import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Banner = () => {
  const { banner } = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "barn.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 70)
        }
      }
    }
  `);

  return (
    <section className="banner relative" style={{ height: "700px" }}>
      <div
        style={{
          // backgroundColor: "rgba(5, 5, 5, 0.4)",
          height: "100%",
        }}
      >
        <GatsbyImage
          image={getImage(banner)}
          alt="banner image"
          imgClassName="object-cover object-70-bottom md:object-right-bottom"
          style={{ height: "100%", zIndex: "-1" }}
        />
      </div>
      <div className="container">
        <div className="absolute left-1/2 md:left-unset top-1/4 md:top-1/2 transform -translate-y-1/2 -translate-x-1/2 md:-translate-x-0 bg-studio-charcoal bg-opacity-80 p-2">
          <div className="flex flex-col items-center border-4 border-studio-burnt-umber px-5 py-3">
            <h2 className="text-3xl md:text-4xl lg:text-6xl text-center font-new-tegomin">
              Handmade&nbsp;Sewn&nbsp;Items
              <br />
              for&nbsp;Everyday&nbsp;Use
            </h2>
            <span className="text-md md:text-xl text-center font-bold whitespace-nowrap">
              Quilts &bull; Hand&nbsp;Towels &bull; Pillows &bull; More
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
