import React from "react";
import { graphql } from "gatsby";
import "animate.css/animate.compat.css";

import Layout from "../../components/layout";
import PageInfo from "../../components/pageInfo";
import Banner from "../../components/banner";
import RenderRichText from "../../components/renderRichText";

const Index = ({ data: { contentfulPage } }) => {
  return (
    <Layout noContainer>
      <PageInfo title={contentfulPage.title} />

      <h1 className="sr-only">{contentfulPage.title}</h1>

      <Banner />

      <div className="container mt-20">
        <section className="mb-20">
          <RenderRichText richText={contentfulPage.body} />
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($contentful_id: String!) {
    contentfulPage(contentful_id: { eq: $contentful_id }) {
      ...PageTemplateFields
    }
  }
`;

export default Index;
